import Deck from "./Deck";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

function Players({players, setPlayers, currentPlayer, onSubmit}) {

    function addPlayer(name) {
        setPlayers(players.concat(name))
    }

    function deletePlayer(name) {
        setPlayers(players.filter(player => name !== player))
    }

    return (
        <List>
            {players.map((player, i) => (
                <ListItem key={i} style={{
                    backgroundColor: (player === currentPlayer) ? "#FF7300" : undefined,
                    color: (player === currentPlayer) ? "#FFFFFF" : undefined,
                }}>
                    <ListItemText primary={player}/>
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => deletePlayer(player)} style={{
                            color: (player === currentPlayer) ? "#FFFFFF" : undefined,
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
            <ListItem>
                <TextField
                    fullWidth
                    placeholder="Player name"
                    onKeyPress={ev => {
                        if (ev.key === 'Enter') {
                            if (ev.ctrlKey) {
                                onSubmit && onSubmit();
                                return
                            }
                            if (ev.target.value !== '') {
                                addPlayer(ev.target.value)
                                ev.target.value = ""
                                ev.preventDefault()
                            }
                        }
                    }}
                />
            </ListItem>
        </List>
    )
}

const useStyles = makeStyles((theme) => ({
    players: {
        order: 0,
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },
    card: {
        order: 1,
        [theme.breakpoints.up('sm')]: {
            order: 0,
        },
    },
}));

const deck = new Deck();

export default function Game() {
    const classes = useStyles({});
    const [card, setCard] = React.useState();
    const [gameOver, setGameOver] = React.useState("");
    const [players, setPlayers] = React.useState([]);

    function reset() {
        setCard(undefined);
        setGameOver("");
    }

    function startGame() {
        reset();
        deck.shuffle()
        drawCard();
    }

    function nextPlayer() {
        let lastPlayerIdx = -1;
        if (card) {
            lastPlayerIdx = players.indexOf(card.player);
        }

        return players[(lastPlayerIdx + 1) % players.length];
    }

    function drawCard() {
        const over = deck.gameOver();
        setGameOver(over);
        if (!over) {
            const player = nextPlayer();
            const card = deck.draw();
            card.player = player;
            setCard(card);
        }
    }

    return (
        <Container maxWidth="md">
            {!card && <>
                <Card elevation={4}>
                    <CardHeader title="Enter players"/>
                    <CardContent>
                        <Players players={players} setPlayers={setPlayers} onSubmit={startGame}/>
                    </CardContent>
                    <CardActions>
                        <Button style={{marginLeft: 'auto'}} onClick={startGame}>
                            Start
                        </Button>
                    </CardActions>
                </Card>
            </>}
            {card && <Grid container spacing={4}>
                <Grid item xs={12} lg={4} className={classes.players}>
                    <Card elevation={4} style={{marginBottom: 32}}>
                        <CardHeader
                            title={`${deck.numDeck()} cards remaining`}
                            subheader={`${(deck.canPopChance() * 100).toFixed(2)}% chance of can pop`}
                        />
                    </Card>
                    <Card elevation={4}>
                        <CardHeader
                            title="Players"
                        />
                        <CardContent>
                            <Players players={players} setPlayers={setPlayers} currentPlayer={card.player}/>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={8} className={classes.card}>
                    {gameOver ? (
                        <Card elevation={4}>
                            <CardHeader title="Game over!" subheader={nextPlayer()}/>
                            <CardContent>
                                <Typography>
                                    {gameOver}! Finish your drink :)
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button style={{marginLeft: 'auto'}} onClick={reset}>
                                    Play again
                                </Button>
                            </CardActions>
                        </Card>
                    ) : (
                        <>
                            <Card onClick={drawCard} elevation={4} style={{userSelect: 'none'}}>
                                <CardHeader
                                    title={`${card.value} ${card.suit} - ${card.rule.title}`}
                                    subheader={card.player}
                                />
                                <CardContent>
                                    <Typography>
                                        {card.rule.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                            {deck.drawn().slice(1).map(card => (
                                <Card elevation={4} style={{marginTop: 16, marginBottom: 16}}>
                                    <CardHeader
                                        title={`${card.value} ${card.suit} - ${card.rule.title}`}
                                        subheader={card.player}
                                    />
                                </Card>
                            ))}
                        </>
                    )}
                </Grid>
            </Grid>}
        </Container>
    )
}