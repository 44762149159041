import React from 'react';
import './App.css';
import Game from "./Game";
import Box from "@material-ui/core/Box";

function App() {
    return <Box pt={4} style={{height: "calc(100% - 32px)"}}>
        <Game/>
    </Box>
}

export default App;
