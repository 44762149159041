const suits = ["♣", "♦", "♥", "♠"];

const uniqueRules = [
    {
        title: "Young ass",
        description: "Players younger than the person who drew this card must drink. If no one is younger than you, take two drinks."
    },
    {
        title: "Phone Call",
        description: "Call someone and say you love them - otherwise finish your drink"
    },
    {
        title: "Beards",
        description: "Players with beards must drink (including Jack)"
    },
    {
        title: "Quarantine",
        description: "Anyone drinks if they have left the house in the last 24 hours"
    },
    {
        title: "Born-again Virgin",
        description: "Drink if you haven't had sex in the last week"
    },
    {
        title: "Old ass",
        description: "Players older than the person who drew this card must drink. If no one is older than you, take two drinks."
    },
    {
        title: "Moneybags",
        description: "The player with the most cash on them must drink"
    },
    {
        title: "Americans",
        description: "All Americans drink. Cheers!",
    },
    {
        title: "Canadians",
        description: "All Canadians drink. Cheers!",
    },
    {
        title: "Guys",
        description: "All guys drink",
    },
    {
        title: "Chicks",
        description: "All chicks drink",
    },
    {
        title: "Hivemind",
        description: "The group decides your next social media post. Post it or finish your drink."
    },
]

const values = {
    "A": [
        {
            title: "Waterfall",
            description: "Everyone starts drinking at the same time, when the card-drawer stops drinking, the person after them can stop, until everyone has stopped."
        },
        {
            title: "Potty Mouth",
            description: "No cursing for the player who drew this card - otherwise drink. Rule is active until the next Ace is drawn."
        }
    ],
    "2": [
        {
            title: "Give Two",
            description: "Give two drinks",
        },
    ],
    "3": [
        {
            title: "Me",
            description: "Take a drink!",
        },
        {
            title: "Three",
            description: "Take three drinks!",
        }
    ],
    "4": [
        {
            title: "Take Two Give Two",
            description: "Take two drinks, give two drinks",
        }
    ],
    "5": [
        {
            title: "Thumb Master",
            description: "The person who drew this card is now the Thumb Master. Whenever they put their thumb up, everyone else has to. Last person to do so takes a drink. Ends when the next Thumb Master is chosen.",
        },
    ],
    "6": [
        {
            title: "Social",
            description: "Everybody drinks. Cheers!",
        },
    ],
    "7": [
        {
            title: "Heaven",
            description: "Last person to point to the sky drinks",
        },
    ],
    "8": [
        {
            title: "Mate",
            description: "Choose a mate. That person has to drink whenever you are required to",
        },
        {
            title: "Twinsies",
            description: "Person who drew this card takes one drink for each sibling they have. If you are an only child, everyone else takes a drink"
        }
    ],
    "9": [
        {
            title: "Rhyme",
            description: "The player who drew this card chooses a word. Going around each player must rhyme with that word. If they take too long or say a rhyme that's already chosen, they drink",
        },
    ],
    "10": [
        {
            title: "Categories",
            description: "The player who drew this card chooses a category. Going around each player must say something in that category. If they take too long or say a word that's already chosen, they drink",
        },
    ],
    "J": [
        {
            title: "Never Have I Ever",
            description: "Starting with the player who drew the card, play a round of Never Have I Ever",
        },
        {
            title: "Show and Tell",
            description: "The person who drew this card must choose and display one item from their surroundings to the group. Show it off. If they don't like it, drink.",
        }
    ],
    "Q": [
        {
            title: "Question Master",
            description: "The player who drew this card is now the question master. If you answer their question, drink. Ends when the next Question Master is chosen.",
        },
    ],
    "K": [
        {
            title: "Make A Rule",
            description: "Make a rule",
        },
    ],
};

export default class Deck {
    // TODO: Pass in difficulties or some bullshit
    constructor() {
        console.log("new deck")
        this._drawn = [];
        this._deck = []
    }

    shuffle() {
        this._drawn = [];
        let remainingUniqueRules = [...uniqueRules];
        this._deck = suits.reduce((accum, suit) => {
            Object.keys(values).forEach(value => {
                const rules = [...values[value]];
                if (remainingUniqueRules.length && Math.random() < uniqueRules.length / 52) {
                    const oneOffIdx = Math.floor(Math.random() * remainingUniqueRules.length);
                    const uniqueRule = remainingUniqueRules[oneOffIdx];
                    rules.push(uniqueRule)
                    remainingUniqueRules.splice(oneOffIdx, 1)
                }
                accum.push({
                    suit,
                    value,
                    rule: rules[Math.floor(Math.random() * rules.length)],
                })
            });
            return accum
        }, [])
    }

    drawn() {
        return this._drawn;
    }

    numDrawn() {
        return this._drawn.length
    }

    numDeck() {
        return this._deck.length
    }

    draw() {
        const randomIdx = Math.floor(Math.random() * this._deck.length);
        this._drawn.unshift(this._deck.splice(randomIdx, 1)[0])
        return this._drawn[0];
    }

    canPopChance() {
        // Some fucking magic math to calculate exponential probability of the can popping
        return 0.00075 * Math.pow(1.15, this._drawn.length);
    }

    gameOver() {
        const numKings = this._drawn.filter(c => c.value === "K").length
        if (numKings === 4) {
            return "4 kings drawn";
        }

        if (Math.random() < this.canPopChance()) {
            return "Can popped"
        }

        return ""
    }
}
